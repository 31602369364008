import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import BlockCTA from "~/components/configurable/BlockCTA"
import Col6 from "~/components/grid/Col6"
import { window } from 'global';
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./blank.scss"
import "./ppa.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import ShoutoutNew from "../configurable/ShoutoutNew"
import Image from "../configurable/Image"
import { GetModal } from "../configurable/Modal"
import * as PPAill from "~/vectors/PPA_anim.inline.svg"
import * as PPAillVertical from "~/vectors/PPA_anim--vertical.inline.svg"




const content = {
  pvSysDeploy: {
    title: 'pvSysDeploy',
    content: (
      <> 
      <div className="modal_content_container" style={{color:"red"}}>    
        <div className="modal_copy_inverter" >      
          <p>Solar Panels are panels that are typically placed on a household’s roof to absorb energy from the sun. The panels convert  this energy (sunlight) into Direct Current electricity (DC electricity). This DC energy is then fed into the inverter. A number of solar panels working together is referred to as a Solar Array. </p>
        </div>        
        <div className="modal_icon_container">
          <div className="modal_icon" >
          <Icon alias="sun"/>
          </div>  
          <div className="modal_icon_text">
            <p >All our panels come with 25+ years warranty </p>
          </div>
        </div> 
      </div>             
      </>
    )
  },
}

const PPA = ({ location }) => {

    //START MODAL LOGIC

    let subtitle;

    const [modalIsOpen, setIsOpen] = React.useState(false);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f000';
    }
  
    const [modalContent, setModalContent] = React.useState(undefined);
  
    const closeModal = React.useCallback(() => {
      setModalContent(undefined);
    }, []);
  
    const onOpenModalFor = React.useCallback((name: string) => {
      setModalContent(content[name]);
    }, []);

    
    //END MODAL LOGIC

  return (
    
    <div >
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={modalContent?.title || ''}
      >
        {modalContent?.content}
      </GetModal>
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Purchase Power Arrangement 
        </Heading>
      </Hero>
      <Block className="segBlock">
        <Section className="top-section" >
          <div className="container" >
            <Col12>
              <Heading level={2} underlined>Understanding PPAs at a glance </Heading>
              <p className="bold-formatting">
                <strong>An increasingly popular way to access renewable energy, lower costs and increase predictability.</strong> Put simply, PPA (Purchase Power Agreement) is an agreement where a developer (us, Green Energy Together) arrange for <strong>full financing of the design and installation of a solar energy system</strong> on a host’s premise(s) - your property portfolio. This includes all permitting, operation, maintenance and management for the duration of the agreement. We then sell the power generated by the panels to the property occupiers (your tenants) at a fixed rate guaranteed to work out lower than the local utility’s retail rate.
              </p>
            </Col12>
          </div>
          <div className="container row ppa-section">
            <Col9 className="ppa-section_text" >
              <div className="service__highlights" >
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="solar-house" />
                  </div>
                  <div className="highlight__contents">
                    <h3>You will get a profit share in the revenue.</h3>
                    <p className="bold-formatting" >The ability to cut out the grid as a middle-man and deliver energy to tenants directly from the power source means a <strong>profit for the duration of the agreement</strong> during which tenants are contracted to buy the power generated by the panels. You’ll receive either a percentage of the profits the solar PV generate or <strong>£100,000 cashback upfront</strong> for every 1,000 PV systems installed. </p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>A well-structured PPA is a win-win for everyone.</h3>
                    <p className="bold-formatting" >Your tenants will enjoy <strong>lower energy bills</strong> and access to truly sustainable and locally generated energy while decreasing reliance on the grid and its price fluctuations. In addition to cash profit, you’ll benefit from improved CSR scores and a boost on your SAP rating while avoiding significant capital costs</p>
                  </div>
                </div>
              </div>
            </Col9>
            <Col3 className="ppa-section_image desktop-padding hide-mobile">
              <img src={require('../../images/_DSC9180.jpg')} alt="" />
            </Col3>
          </div>

        </Section>
        <div className="PPApage-ill-cont"  >
            <PPAill className="PPApage-ill-item hidden-xs" style={{minWidth:"60vw"}} />
            <PPAillVertical className="PPApage-ill-item--vertical visible-xs" style={{minWidth:"60vw"}} />
          </div>
        <Section>
          <div className="container">
            <Col12>
              <Heading level={2} underlined>Our Proposal  </Heading>
              <p className="bold-formatting">
              The Green Energy Together team is pleased to present our proposal to investigate the feasibility of funded Solar PV systems for your property portfolio under our Power Purchase Scheme (PPA). Green Energy Together is teamed with a number of partners that will finance, own and operate the solar power systems for your facilities. Green Energy Together has the funding in place to make this project a success and our experience includes securing and completing over 400 MW of installations in the UK. 
              </p>
            </Col12>
          </div>
          <div className="container row ppa-section">
            <Col9 className="ppa-section_text" >
              <div className="service__highlights" >
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="solar-house" />
                  </div>
                  <div className="highlight__contents">
                    <h3>Pricing options </h3>
                    <p className="bold-formatting">We guarantee that the cost of the energy used by the homeowner, generated by the solar and stored in the batteries, will be <strong>less than the average of the big 6 variable standard tariffs</strong> for the entirety of the contract (25 Years), however, to ensure you tenants can always ensure that the solar is no more expensive than they can buy at the grid, if at any time they can purchase energy from the grid for less than we are charging for the solar, we will match that price. </p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>Profit sharing  </h3>
                    <p className="bold-formatting">The scheme allows the housing provider to <strong>benefit from the installation</strong>, this can be several different ways, either long term (25y) profit share, or upfront payments on the installation of solar or a mix of both. </p>
                  </div>
                </div>
              </div>
            </Col9>
            <Col3 style={{marginRight:0, marginTop:"8%"}} >
              <div className="ppa-highlight-block">
                <Heading level={4} className="ppa-highlight-heading">Share the profit</Heading>
                <img src={require('../../images/fa-solid_solar-panel.svg')} alt="panels-icon" />
                <p>For every system installed</p>
                <img className="ppa-highlight-block-arrow" src={require('../../images/arrow.svg')} alt="arrow-icon" />
                <img src={require('../../images/whh_poundsquare.svg')} alt="pounds-icon" />
                <p>Get chasback upfront</p>
              </div>
            </Col3>
          </div>
        </Section>
      </Block>
      {/* <div className="container">
        <ShoutoutNew
          image={<Image src={require('../../images/installers.jpg')} title="PV System Deployment" />}
          text={
            <div style={{ paddingLeft: 30, paddingRight: 15 }}>
              <h2 style={{color:'#051c3f'}} >PV System Deployment </h2>
                <p  style={{marginTop:12, lineHeight:1.3, fontSize:'1em'}}>
                  Our in-house team can utilise the company’s well-tested logistics chain to quickly carry out the delivery. We’re able to install <strong style={{color:'#051c3f'}} >1,000+ PV Systems per month</strong> in suitable properties. This is the installation process: 
                </p>
                <div className="ppa-list" style={{marginLeft:20}}>
                  <ol >
                    <li  className="ppa-list_item" ><span>Engineering, Procurement and Construction (“EPC”) surveys  PV System design  </span></li>
                    <li className="ppa-list_item"><span>Procurement of consents (e.g., from the Distribution Network Operator (DNO) and the occupier) Sourcing of all components  </span></li>
                    <li className="ppa-list_item"><span>Installation  </span></li>
                    <li className="ppa-list_item"><span>Health and safety compliance </span></li>
                  </ol>
                </div> */}

                
              {/* <button 
              onClick={() => {onOpenModalFor('pvSysDeploy')}}>
                Find out more
              </button> */}
              
            {/* </div>
          }
        />
      </div> */}
        <ShoutoutNew
          color={'#3c96c5'}
          image={require('../../images/installers.jpg')}
          text={
            <>
              <Heading underlined>PV System Deployment</Heading>
              <p  style={{marginTop:12, lineHeight:1.3, fontSize:'1em'}}>
                  Our in-house team can utilise the company’s well-tested logistics chain to quickly carry out the delivery. We’re able to install <strong style={{color:'#051c3f'}} >1,000+ PV Systems per month</strong> in suitable properties. This is the installation process: 
                </p>
                <div className="ppa-list" style={{marginLeft:20}}>
                  <ol >
                    <li  className="ppa-list_item" ><span>Engineering, Procurement and Construction (“EPC”) surveys  PV System design  </span></li>
                    <li className="ppa-list_item"><span>Procurement of consents (e.g., from the Distribution Network Operator (DNO) and the occupier) Sourcing of all components  </span></li>
                    <li className="ppa-list_item"><span>Installation  </span></li>
                    <li className="ppa-list_item"><span>Health and safety compliance </span></li>
                  </ol>
                </div>
                <div className="div" style={{marginTop:"2em", marginBottom:"2em"}}>
                <BlockCTA
                  url="/contact-us"
                  arrow="right">
                  Find out more
                </BlockCTA>
                </div>
              
            </>
          }
        />



      <Block className="segBlock">
        <Section  className="top-section">
          <div className="container">
            <Col12>
              <Heading level={2} underlined>Tenant Advantages </Heading>
              <p className="bold-formatting">
              The key advantage for tenants is the ability to buy green energy at a <strong>fixed, predictable rate guaranteed to be lower than the local utility’s retail rate.</strong> In other words - guaranteed savings on their energy bills for the duration of the contract. In addition to financial savings, there are other advantages of joining a PPA, including: 
              </p>
            </Col12>
          </div>
          <div className="container row ppa-section">
            <Col9 className="ppa-section_text" >
              <div className="service__highlights ppa" >
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>Energy needs future-proofed </h3>
                    <p className="bold-formatting">While not all our energy needs are met with electricity right now, they will be soon. As an example, technology runs on electricity. But electricity is controlled by electronics, which are getting <strong>smarter and often cheaper!</strong> Making it more affordable for everyone to eventually switch to a greener solution. </p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>The future is electric  </h3>
                    <p className="bold-formatting">The UK plans to ban the sale of new petrol and diesel cars by 2030, and by 2035 gas boilers are expected to be phased out for electric heat pumps. </p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>Less reliance on the grid  </h3>
                    <p  className="bold-formatting">Experts warn that <strong>a single grid is no longer sufficient</strong> to guarantee uninterrupted access to energy, increasing the occurrence of disruptions.  </p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>Protect against fluctuations </h3>
                    <p className="bold-formatting">Ensuring control over utility costs and securing a predictable cost of electricity is a powerful <strong>tool to bring stability</strong> to any business or community.  </p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="highlight__icon">
                    <Icon alias="energy-transform" />
                  </div>
                  <div className="highlight__contents">
                    <h3>Energy trends    </h3>
                    <p  className="bold-formatting"><strong>Electricity costs in the UK have increased</strong> by 60% in the last 6 years and they are expected to rise a further 70% by 2028.  </p>
                  </div>
                </div>
              </div>
            </Col9>
            <Col3 className="ppa-section_image">
              <img className="ppa-section_image_ind" src={require('../../images/side_image3.png')} alt="" />
              <img className="ppa-section_image_ind" src={require('../../images/side_image2.png')} alt="" />
            </Col3>
          </div>
        </Section>
        <Section>
          <div className="container">
            <Col12>
              <Heading level={2} underlined>Tenant Advantages </Heading>
            </Col12>
          </div>
          <div className="container row" style={{marginTop:"2%"}}>
            <Col6 className="tenant_highlights">
              <div className="tenant_highlights_item">
                <div className="highlight__icon" style={{backgroundColor: "#70b33b"}} >
                  <Icon alias="energy-transform" />
                </div>
                <div className="highlight__contents">
                  <h3 >Low-Income Tenants  </h3>
                  <p className="bold-formatting">Currently more than one in five households cannot afford to heat their homes. Social landlords <strong>can bring savings</strong> to their whole community reducing fuel poverty.   </p>
                </div>
              </div>
            </Col6>
            <Col6 className="tenant_highlights">
              <div className="tenant_highlights_item">
                <div className="highlight__icon" style={{backgroundColor: "#70b33b"}}>
                  <Icon alias="energy-transform" />
                </div>
                <div className="highlight__contents">
                  <h3>Business Owners  </h3>
                  <p className="bold-formatting">Sustainability sells and businesses can get ahead of the market trends by going solar - in fact, 63% of consumers are willing to pay a premium for companies that<strong> align with their values</strong>. </p>
                </div>
              </div>
            </Col6>
          </div>
        </Section>
      </Block>

    </div>
  )
}

export default PPA
